import { Box, Card } from '@mui/material'
import Image from 'next/image'
import { useMemo } from 'react'
import { Video } from '~/__generated__/graphql'
import { DurationLabel } from '~/components/DurationLabel'
import { NumberIcon } from '~/components/NumberIcon'
import { UpComingChip } from '~/features/play_list/components/chips/UpComingChip'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { thumbnailPlaceholder } from '~/libs/constants'
import { Colors } from '~/libs/mui/theme'
import { getVideoWatchedPercent } from '~/libs/video/getVideoWatchedPercent'

interface VideoRankingItemCardProps {
  video: Video
  rankNumber: number
  onClickCard: () => void
}

export function VideoRankingItemCard({
  video,
  rankNumber,
  onClickCard,
}: VideoRankingItemCardProps): JSX.Element {
  const { isSm } = useMediaQuery()
  const watchedPercent = useMemo(() => {
    if (!video.viewingTime) return undefined
    return getVideoWatchedPercent(
      { minutes: video.duration?.minutes ?? 0, seconds: video.duration?.seconds ?? 0 },
      { minutes: video.viewingTime.viewingMin ?? 0, seconds: video.viewingTime.viewingSecond ?? 0 },
    )
  }, [video])
  const isUpComing = video.status === 'upcoming'
  return (
    <Box onClick={onClickCard} position='relative' pl={5} sx={{ overflow: 'visible' }}>
      <Card
        sx={{
          width: { xs: 108, sm: 162 },
          height: { xs: 160, sm: 240 },
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <Image
          alt='thumbnail'
          src={video.portraitThumbnail || ''}
          fill
          style={{
            objectFit: 'cover',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
          placeholder='blur'
          blurDataURL={thumbnailPlaceholder}
        />
        {isUpComing && <UpComingChip />}
        {/* 視聴時間ラベル */}
        <Box position='absolute' top={2} right={2}>
          <DurationLabel
            durationMinute={video.duration?.minutes ?? 0}
            durationSecond={video.duration?.seconds ?? 0}
          />
        </Box>
        {/* 視聴済みバー */}
        {!!watchedPercent && (
          <Box
            position='absolute'
            bottom={0}
            left={0}
            height='3px'
            width={`${watchedPercent * 100}%`}
            sx={{ backgroundColor: Colors.red, borderRadius: '1px' }}
          />
        )}
      </Card>
      {/* ランキング番号表記 */}
      <Box position='absolute' bottom='-12px' left={0}>
        <NumberIcon displayNumber={rankNumber} size={isSm ? 96 : 80} />
      </Box>
    </Box>
  )
}
