import { useQuery } from '@apollo/client'
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone'
import { Box, Button, Stack } from '@mui/material'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { GetHomeScreenDocument, GetHomeScreenQuery, HomeScreen } from '~/__generated__/graphql'
import { SectionLabel } from '~/components/SectionLabel'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { DefaultCardGroup, RankingCarGroup } from '../CardGroup'

interface SubCategoryRowProps {
  homeScreen: HomeScreen
  /** ビデオカードをクリック */
  onClickVideoItem: (videoId: number, isKeyVisual: boolean) => void
  /** カテゴリー横の『全て表示』をクリック */
  onClickDisplayAll: () => void
}

export function SubCategoryRow({
  homeScreen,
  onClickDisplayAll,
  onClickVideoItem,
}: SubCategoryRowProps): JSX.Element | null {
  const { data } = useQuery<GetHomeScreenQuery>(GetHomeScreenDocument, {
    variables: { id: homeScreen.id },
  })
  const { isSm } = useMediaQuery()

  const videos = useMemo(() => data?.homeScreens[0].videos ?? undefined, [data])

  if (videos?.length === 0) {
    return null
  }

  return (
    <Stack spacing={1}>
      <Box
        display='flex'
        justifyContent={{ sm: 'flex-start' }}
        gap={{ xs: 0, sm: 1 }}
        onClick={isSm ? undefined : onClickDisplayAll}
      >
        <SectionLabel>{homeScreen.category?.name}</SectionLabel>
        <Button onClick={isSm ? onClickDisplayAll : undefined} sx={{ minHeight: 0 }}>
          {isSm ? (
            <Trans i18nKey='show_all' />
          ) : (
            <KeyboardDoubleArrowRightTwoToneIcon fontSize='large' />
          )}
        </Button>
      </Box>
      {homeScreen.category?.rankable ? (
        <RankingCarGroup videos={videos} onClickVideoItem={onClickVideoItem} />
      ) : (
        <DefaultCardGroup videos={videos} onClickVideoItem={onClickVideoItem} />
      )}
    </Stack>
  )
}
