import { FC } from 'react'

const ARROW_WIDTH = 78

type KeyVisualsCarouselArrowProps = {
  type: 'prev' | 'next' | 'none'
  onClickArrow: () => void
}

export const KeyVisualsCarouselArrow: FC<KeyVisualsCarouselArrowProps> = ({
  type,
  onClickArrow,
}) => {
  switch (type) {
    case 'prev':
      return (
        <button
          onClick={onClickArrow}
          className='control-arrow control-prev'
          style={{ width: ARROW_WIDTH }}
        />
      )
    case 'next':
      return (
        <button
          onClick={onClickArrow}
          className='control-arrow control-next'
          style={{ width: ARROW_WIDTH }}
        />
      )
    default:
      return <></>
  }
}
