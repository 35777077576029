import { FC, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { userLoginState } from '~/recoil/auth'
import { ScreenPopupModel } from '../hooks/createModel'
import { useScreenPopup } from '../hooks/useScreenPopup'
import AdvertisementModalContent from './AdvertisementModalContent'

const AdvertisementModal: FC = () => {
  const loginState = useRecoilValue(userLoginState)
  const {
    onSelectAppearModalNextTime,
    data: screenPopups,
    error,
    handleShowed,
  } = useScreenPopup(loginState)

  if (error) {
    console.error(error)
    throw error
  }

  // モーダルを閉じる関数
  const onClose = useCallback(
    (isNotShowAgain: boolean, screenPopup: ScreenPopupModel) => {
      onSelectAppearModalNextTime(isNotShowAgain, screenPopup.id)
      handleShowed(screenPopup.id)
    },
    [onSelectAppearModalNextTime, handleShowed],
  )

  return (
    <>
      {screenPopups && screenPopups.length > 0 && (
        <AdvertisementModalContent
          screenPopup={screenPopups[0]}
          key={screenPopups[0].id}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default AdvertisementModal
