import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, styled, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

const ScrollArea = styled(Box)(() => ({
  display: 'flex',
  overflowX: 'scroll',
  overflowY: 'hidden',
  gap: 6,
  '&::-webkit-scrollbar': { display: 'none' },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
}))

const SideButton = styled(Button)((theme) => ({
  position: 'absolute',
  height: '100%',
  top: 0,
  color: 'white',
  animation: 'appear .2s ease',
  background: 'rgba(0, 0, 0, 0.3)',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.8)',
  },
  '@keyframes appear': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

interface SlideGroupProps {
  children?: ReactNode
  /** 一番左に到達した際に発火するイベント (NOTE: 初期配置が一番左なので、レンダリングと同時に発火される) */
  onMostLeft?: () => void
  /** 一番右に到達した際に発火するイベント (NOTE: コンテンツ領域が少なくスライダー(矢印)が表示されない場合、レンダリングと同時に発火される*/
  onMostRight?: () => void
}

export function SlideGroup({ children, onMostLeft, onMostRight }: SlideGroupProps): JSX.Element {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const scrollRef = useRef<HTMLDivElement>()
  const [mostLeft, setMostLeft] = useState(true)
  const [mostRight, setMostRight] = useState(false)

  // onClick left button
  const slidePrev = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        left: scrollRef.current.scrollLeft - scrollRef.current.clientWidth,
        behavior: 'smooth',
      })
    }
  }, [])
  // onClick right button
  const slideNext = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        left: scrollRef.current.scrollLeft + scrollRef.current.clientWidth,
        behavior: 'smooth',
      })
    }
  }, [])
  // update button visibility
  // call when scroll position or size changed
  const didScroll = useCallback(
    (target: HTMLDivElement | undefined = scrollRef.current) => {
      if (target) {
        const isMostLeft = target.scrollLeft === 0
        setMostLeft(isMostLeft)
        if (isMostLeft) onMostLeft?.()
        const isMostRight = target.offsetWidth + target.scrollLeft >= target.scrollWidth
        setMostRight(isMostRight)
        if (isMostRight) onMostRight?.()
      }
    },
    [onMostLeft, onMostRight],
  )
  // update after first rendering
  useEffect(() => {
    didScroll(scrollRef.current)
  }, [didScroll, scrollRef])
  // observe window resize
  useEffect(() => {
    const handleResize = () => {
      didScroll()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [didScroll])

  return (
    <Box position='relative' className='SlideGroup'>
      <ScrollArea
        ref={scrollRef}
        onScroll={(e) => {
          didScroll(e.currentTarget)
        }}
      >
        {children}
      </ScrollArea>

      {isSm && (
        <>
          <SideButton
            sx={{
              left: 0,
              display: mostLeft ? 'none' : 'block',
            }}
            onClick={() => slidePrev()}
          >
            <ArrowBackIos />
          </SideButton>
          <SideButton
            sx={{
              right: 0,
              display: mostRight ? 'none' : 'block',
            }}
            onClick={() => slideNext()}
          >
            <ArrowForwardIos />
          </SideButton>
        </>
      )}
    </Box>
  )
}
