import { useQuery } from '@apollo/client'
import { Container } from '@mui/material'
import { useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { GetScreenPopupsDocument, GetScreenPopupsQuery } from '~/__generated__/graphql'
import { LoadingBackdrop } from '~/components/LoadingBackdrop'
import { useHomeVideoList } from '~/features/home/hooks/useHomeVideoList'
import AdvertisementModal from '~/features/popup/components/AdvertisementModal'
import { KeyVisualModel } from '~/features/popup/hooks/createModel'
import { useKeyVisual } from '~/features/popup/hooks/useKeyVisual/useKeyVisual'
import * as GA from '~/libs/ga'
import { previewVideoState } from '~/recoil/preview'
import { HomePageContent } from '../components/HomePageContent/HomePageContent'

type Props = {
  searchParams?: {
    utm_medium?: string
    utm_source?: string
    utm_campaign?: string
    utm_content?: string
    utm_term?: string
  }
}

export function HomePageComponent({ searchParams }: Props): JSX.Element {
  const { push } = useRouter()
  const params = useSearchParams()
  const homeScreens = useHomeVideoList()
  const { keyVisualModels, loading: isKeyVisualsLoading } = useKeyVisual()
  const {} = useQuery<GetScreenPopupsQuery>(GetScreenPopupsDocument) // 高速化のため、事前に取得しておく
  const setPreviewVideoId = useSetRecoilState(previewVideoState)

  useEffect(() => {
    if (searchParams?.utm_medium) {
      localStorage.setItem('utm_medium', searchParams.utm_medium as string)
    }
    if (searchParams?.utm_source) {
      localStorage.setItem('utm_source', searchParams.utm_source as string)
    }
    if (searchParams?.utm_campaign) {
      localStorage.setItem('utm_campaign', searchParams.utm_campaign as string)
    }
    if (searchParams?.utm_content) {
      localStorage.setItem('utm_content', searchParams.utm_content as string)
    }
    if (searchParams?.utm_term) {
      localStorage.setItem('utm_term', searchParams.utm_term as string)
    }
  }, [searchParams])

  const onClickDisplayAll = useCallback(
    (subCategoryId: number) => {
      GA.sendEvent({
        event: 'click',
        screen: 'stream',
        category: 'text',
        action: 'stream_all',
        label: `${subCategoryId}`,
      })
      void push(`/search/categories/${subCategoryId}/videos`)
    },
    [push],
  )

  const onClickVideoItem = useCallback(
    (videoId: number) => {
      GA.sendEvent({
        event: 'click',
        screen: 'stream',
        category: 'image',
        action: 'stream_poster',
        label: `${videoId}`,
      })
      setPreviewVideoId(videoId)
    },
    [setPreviewVideoId],
  )

  const onClickKeyVisual = useCallback(
    (keyVisual: KeyVisualModel) => {
      const video = keyVisual.videoInfo

      if (video) {
        GA.sendEvent({
          event: 'click',
          screen: 'stream',
          category: 'image',
          action: 'stream_banner',
          label: `${video.id}`,
        })
        setPreviewVideoId(Number(video.id))
      } else {
        GA.sendEvent({
          event: 'click',
          screen: 'stream',
          category: 'image',
          action: 'key_visual',
          label: `${keyVisual.id}`,
        })

        if (keyVisual.buttonInfo?.redirectUrl) {
          window.open(keyVisual.buttonInfo.redirectUrl, '_blank', 'noreferrer')
        }
      }
    },
    [setPreviewVideoId],
  )

  if (homeScreens === 'loading' || isKeyVisualsLoading) {
    return <LoadingBackdrop />
  }

  return (
    <Container maxWidth='xl' disableGutters>
      <HomePageContent
        keyVisuals={keyVisualModels}
        homeScreens={homeScreens}
        onClickDisplayAll={onClickDisplayAll}
        onClickVideoItem={onClickVideoItem}
        onClickKeyVisual={onClickKeyVisual}
      />
      <AdvertisementModal />
    </Container>
  )
}
