import browserEnv from './env/browserEnv'

let user_id: number | undefined = undefined
export const setUserId = (id?: number) => (user_id = id)
export const getClientId = () => {
  const match = document.cookie.match(/_ga=(.+?)(;|$)/)

  if (match) {
    return match[1].split('.').slice(-2).join('.')
  }
  return undefined
}

type UserType =
  | 'notLogin'
  | 'free'
  | 'trial'
  | 'trialCancel'
  | 'paying'
  | 'payingCancel'
  | 'canceled'
let user_type: UserType = 'notLogin'
export const setUserType = (type: UserType) => (user_type = type)

export const sendEvent = (params: {
  event: string
  screen: string
  category: string
  action: string
  label?: string
  value?: string
  currency?: string
  coupon?: string
}) => {
  if (browserEnv.NEXT_PUBLIC_GTM_ENABLED) {
    window?.dataLayer?.push({ ...params, user_id, user_type })
  }
}
