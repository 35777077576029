import { useEffect, useState } from 'react'

/**
 * 非表示対象は広告モーダルのidを配列でもつ
 */
export const STORAGE_KEY = 'advertisement_modal_skip'

/**
 * 広告モーダルの再表示をローカルストレージで管理する
 */
export function useScreenPopupLocalStorageManager() {
  const [storages, setStorages] = useState<string[]>([])
  const storage = localStorage.getItem(STORAGE_KEY)

  useEffect(() => {
    if (storage !== null) {
      setStorages(JSON.parse(storage))
    }
  }, [storage])

  const setScreenPopupIdLocalStorage = (modalId: string) => {
    const newStorages = [...storages, modalId]
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newStorages))
    setStorages(newStorages)
  }

  const isExistOnLocalStorage = (modalId: string) => {
    return storages.includes(modalId)
  }

  return { setScreenPopupIdLocalStorage, isExistOnLocalStorage }
}
