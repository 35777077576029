import { SvgIcon } from '@mui/material'
import React, { ComponentProps } from 'react'
import NumberIcon1 from '~/assets/icons/numbers/1.svg'
import NumberIcon2 from '~/assets/icons/numbers/2.svg'
import NumberIcon3 from '~/assets/icons/numbers/3.svg'
import NumberIcon4 from '~/assets/icons/numbers/4.svg'
import NumberIcon5 from '~/assets/icons/numbers/5.svg'
import NumberIcon6 from '~/assets/icons/numbers/6.svg'
import NumberIcon7 from '~/assets/icons/numbers/7.svg'
import NumberIcon8 from '~/assets/icons/numbers/8.svg'
import NumberIcon9 from '~/assets/icons/numbers/9.svg'
import NumberIcon10 from '~/assets/icons/numbers/10.svg'

type NumberIconProps = {
  /** 1~10の範囲で指定  */
  displayNumber: number
  size: number
} & ComponentProps<typeof SvgIcon>

const numberIcons: { [key: number]: { component: React.ComponentType; viewBox: string } } = {
  1: { component: NumberIcon1, viewBox: '0 0 42 82' },
  2: { component: NumberIcon2, viewBox: '0 0 65 83' },
  3: { component: NumberIcon3, viewBox: '0 0 63 85' },
  4: { component: NumberIcon4, viewBox: '0 0 67 82' },
  5: { component: NumberIcon5, viewBox: '0 0 62 84' },
  6: { component: NumberIcon6, viewBox: '0 0 64 84' },
  7: { component: NumberIcon7, viewBox: '0 0 68 82' },
  8: { component: NumberIcon8, viewBox: '0 0 64 85' },
  9: { component: NumberIcon9, viewBox: '0 0 64 83' },
  10: { component: NumberIcon10, viewBox: '0 0 108 85' },
}

export function NumberIcon({
  displayNumber,
  size = 24,
  sx,
  ...props
}: NumberIconProps): JSX.Element {
  if (displayNumber < 1 || displayNumber > 10) {
    return <></>
  }

  const { component: NumberIconComponent, viewBox } = numberIcons[displayNumber]

  return (
    <SvgIcon {...props} sx={{ fontSize: size }} component={NumberIconComponent} viewBox={viewBox} />
  )
}
