import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Colors } from '~/libs/mui/theme'

export const SectionLabel: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Box display='flex' gap={1} alignItems='center'>
      <Box width='4px' height='18px' bgcolor={Colors.yellow}></Box>
      <Box fontSize={{ xs: 17, sm: 18 }}>{children}</Box>
    </Box>
  )
}
