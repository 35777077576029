import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  useMediaQuery,
} from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { FC, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import theme, { Colors } from '~/libs/mui/theme'
import { ScreenPopupModel } from '../hooks/createModel'

type AdvertisementModalContentProps = {
  /** ダイアログ情報 */
  screenPopup: ScreenPopupModel
  onClose: (isNotShowAgain: boolean, screenPopup: ScreenPopupModel) => void
}

const AdvertisementModalContent: FC<AdvertisementModalContentProps> = ({
  screenPopup,
  onClose,
}) => {
  const { t } = useTranslation()
  const { push } = useRouter()
  const [isNotShowAgain, setIsNotShowAgain] = useState(false)
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const borderRadius = screenPopup.buttonInfo ? '30px 30px 0 0' : '30px'

  const onClickAdvertisement = useCallback(
    (screenPopup: ScreenPopupModel) => {
      if (screenPopup.videoId) {
        void push(`/videos/${screenPopup.videoId}/play`)
        return
      }
      if (screenPopup.buttonInfo?.redirectUrl) {
        void window.open(screenPopup.buttonInfo.redirectUrl, '_blank')
        return
      }
    },
    [push],
  )

  return (
    <Modal
      open
      onClose={() => onClose(isNotShowAgain, screenPopup)}
      aria-labelledby='advertisement-title'
      aria-describedby='advertisement-description'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1199,
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        sx={{
          outline: 'none',
          boxShadow: 'none',
          ':focus': {
            outline: 'none',
            boxShadow: 'none',
          },
        }}
      >
        <Box
          onClick={() => onClickAdvertisement(screenPopup)}
          onTouchEnd={() => onClickAdvertisement(screenPopup)}
          sx={{
            backgroundColor: Colors.color1A1A18,
            borderRadius,
            position: 'relative',
          }}
        >
          <Box display='flex'>
            <Box width={isSm ? 360 : 270} height={isSm ? 504 : 378}>
              <Image
                src={screenPopup.image}
                style={{
                  objectFit: 'contain',
                  borderRadius,
                }}
                fill
                alt={screenPopup.title}
              />
            </Box>
          </Box>
        </Box>
        {screenPopup.buttonInfo && (
          <Button
            variant='contained'
            fullWidth
            sx={{
              borderRadius: '0 0 30px 30px',
              backgroundColor: screenPopup.buttonInfo.color,
              color: screenPopup.buttonInfo.textColor,
              height: {
                xs: 46,
                sm: 56,
              },
              fontSize: {
                xs: 14,
                sm: 16,
              },
            }}
            onClick={() => onClickAdvertisement(screenPopup)}
            onTouchEnd={() => onClickAdvertisement(screenPopup)}
          >
            {screenPopup.buttonInfo.text ?? <Trans i18nKey='check_detail' />}
          </Button>
        )}

        <Box display='flex' justifyContent='center' width='100%' marginTop={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    borderRadius: '30px',
                  }}
                  checked={isNotShowAgain}
                  onChange={() => setIsNotShowAgain(!isNotShowAgain)}
                />
              }
              label={t('do_not_show_again')}
            />
          </FormGroup>
        </Box>
        <Box display='flex' justifyContent='center' width='100%' marginTop={2}>
          <Box>
            <Button
              variant='outlined'
              color='inherit'
              sx={{
                justifyContent: 'center',
                flexGrow: 1,
                width: 164,
                height: 46,
                borderRadius: '30px',
              }}
              onClick={() => onClose(isNotShowAgain, screenPopup)}
              onTouchEnd={(e) => {
                e.preventDefault()
                onClose(isNotShowAgain, screenPopup)
              }}
            >
              <Trans i18nKey='close' />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default AdvertisementModalContent
