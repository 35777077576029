import { PlayArrow } from '@mui/icons-material'
import { Box, Button, Hidden, Typography } from '@mui/material'
import Image from 'next/image'
import { Trans } from 'react-i18next'
import { DurationLabel } from '~/components/DurationLabel'
import { KeyVisualModel } from '~/features/popup/hooks/createModel'
import { thumbnailPlaceholder } from '~/libs/constants'
import { Colors } from '~/libs/mui/theme'

interface KeyVisualProps {
  keyVisual: KeyVisualModel
  onClickThumbnail: () => void
}

export function KeyVisual({ keyVisual, onClickThumbnail }: KeyVisualProps): JSX.Element {
  return (
    <Box height={{ sm: '448px' }} sx={{ aspectRatio: { xs: '16 / 7', sm: 'auto' } }}>
      <Box
        position='relative'
        width='100%'
        height='100%'
        onClick={onClickThumbnail}
        sx={{ cursor: 'pointer' }}
      >
        <Image
          src={keyVisual.image}
          alt={keyVisual.title}
          style={{ objectFit: 'cover' }}
          fill
          priority
          placeholder='blur'
          blurDataURL={thumbnailPlaceholder}
        />
      </Box>
      <Hidden smDown>
        <Curtain
          title={keyVisual.title}
          videoInfo={keyVisual.videoInfo}
          buttonInfo={keyVisual.buttonInfo}
          onClick={onClickThumbnail}
        />
      </Hidden>
    </Box>
  )
}

/**
 * PCの時にサムネ画像の上に載せる
 * モバイル時には非常時
 */
interface CurtainProps {
  title: string
  videoInfo: KeyVisualModel['videoInfo']
  buttonInfo: KeyVisualModel['buttonInfo']
  onClick: () => void
}

function Curtain({ title, videoInfo, buttonInfo, onClick }: CurtainProps) {
  return (
    <Box
      position='absolute'
      display='flex'
      alignItems='end'
      paddingX={10}
      paddingY={6}
      sx={{
        inset: 0,
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.3) 61.72%, rgba(0, 0, 0, 0) 94.05%)',
      }}
    >
      <Box width='50%' textAlign='left'>
        <Typography color={Colors.yellow} fontSize={28} lineHeight={1}>
          {title}
        </Typography>
        {videoInfo && (
          <>
            <DurationLabel durationMinute={videoInfo.minutes} durationSecond={videoInfo.seconds} />
            <Typography maxWidth={360} variant='body1' mt={1}>
              {videoInfo.description}
            </Typography>
          </>
        )}
        {buttonInfo && (
          <Box mt={1}>
            <Button
              onClick={onClick}
              variant='contained'
              endIcon={<PlayArrow />}
              sx={{
                minWidth: 156,
                justifyContent: 'space-between',
                backgroundColor: buttonInfo.color ?? Colors.yellow,
                color: buttonInfo.textColor ?? Colors.color0D0D0E,
              }}
            >
              {buttonInfo.text ?? <Trans i18nKey='watch_now' />}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
