import { useMutation, useQuery } from '@apollo/client'
import { useCallback, useMemo, useState } from 'react'
import {
  GetScreenPopupsDocument,
  GetScreenPopupsQuery,
  HideScreenPopupDocument,
} from '~/__generated__/graphql'
import { UserLoginState } from '~/recoil/auth'
import { ScreenPopupModel, createButtonInfo } from '../createModel'
import { useScreenPopupLocalStorageManager } from './useScreenPopupLocalStorageManager'

export const useScreenPopup = (loginState: UserLoginState) => {
  const { data, error } = useQuery<GetScreenPopupsQuery>(GetScreenPopupsDocument)
  const [hideScreenPopup] = useMutation(HideScreenPopupDocument, {
    refetchQueries: [{ query: GetScreenPopupsDocument }],
  })
  const { setScreenPopupIdLocalStorage, isExistOnLocalStorage } =
    useScreenPopupLocalStorageManager()
  const [showedScreenPopupIds, setShowedScreenPopupIds] = useState<string[]>([])

  const handleShowed = useCallback(
    (screenPopupId: string) => {
      setShowedScreenPopupIds([...showedScreenPopupIds, screenPopupId])
    },
    [showedScreenPopupIds],
  )

  const _screenPopups = useMemo(() => {
    if (data?.screenPopups.edges == null) return []

    // オブジェクトからの参照だとsortが使えないので、一度arrayに戻す
    const popupList = [...data.screenPopups.edges].filter(
      ({ node }) => node && !showedScreenPopupIds.includes(node.id),
    )

    // 優先度が高い順に並び替える
    const sortedByPriority = popupList.sort((a, b) => {
      const aRowOrderRank = a.node?.rowOrderRank ?? 0
      const bRowOrderRank = b.node?.rowOrderRank ?? 0
      return aRowOrderRank > bRowOrderRank ? 1 : -1
    })

    if (loginState !== 'signedIn') {
      const filteredPopups = sortedByPriority.filter((popup) => {
        if (popup.node?.id === undefined) return false // idがないのは異常系なので、含めない
        return !isExistOnLocalStorage(popup.node.id)
      })
      return filteredPopups
    } else {
      return sortedByPriority
    }
  }, [data, isExistOnLocalStorage, loginState, showedScreenPopupIds])

  const onSelectAppearModalNextTime = useCallback(
    (isNotShowAgain: boolean, screenPopupId: string) => {
      if (!isNotShowAgain) return
      if (loginState === 'signedIn') {
        hideScreenPopup({ variables: { screenPopupId } })
      } else {
        // ログインしていない場合は、localStorageに保存する
        setScreenPopupIdLocalStorage(screenPopupId)
      }
    },
    [hideScreenPopup, loginState, setScreenPopupIdLocalStorage],
  )

  const screenPopups: ScreenPopupModel[] = _screenPopups
    .filter(({ node }) => node !== null)
    .map(({ node }) => {
      const { button, image, title, rowOrderRank, id } = node!
      const buttonInfo = button ? createButtonInfo(button) : undefined
      const videoId = button?.video?.id

      return {
        id,
        title,
        image,
        rowOrderRank,
        buttonInfo,
        videoId,
      }
    })

  return {
    onSelectAppearModalNextTime,
    data: screenPopups,
    error,
    handleShowed,
  }
}
