import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { KeyVisualModel } from '~/features/popup/hooks/createModel'
import { KeyVisual } from './KeyVisual'
import { KeyVisualsCarouselArrow } from './KeyVisualsCarouselArrow'

interface KeyVisualsCarouselProps {
  keyVisuals: KeyVisualModel[]
  onClickThumbnail: (keyVisual: KeyVisualModel) => void
}

export function KeyVisualsCarousel({
  keyVisuals,
  onClickThumbnail,
}: KeyVisualsCarouselProps): JSX.Element {
  return (
    <Carousel
      showStatus={false}
      infiniteLoop
      showThumbs={false}
      renderArrowPrev={(clickHandler) => (
        <KeyVisualsCarouselArrow onClickArrow={clickHandler} type='prev' />
      )}
      renderArrowNext={(clickHandler) => (
        <KeyVisualsCarouselArrow onClickArrow={clickHandler} type='next' />
      )}
    >
      {keyVisuals.map((keyVisual, index) => (
        <KeyVisual
          key={index}
          keyVisual={keyVisual}
          onClickThumbnail={() => onClickThumbnail(keyVisual)}
        />
      ))}
    </Carousel>
  )
}
