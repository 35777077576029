import { Box, Stack } from '@mui/material'
import { HomeScreen } from '~/__generated__/graphql'
import { KeyVisualsCarousel } from '~/components/KeyVisualsCarousel'
import { KeyVisualModel } from '~/features/popup/hooks/createModel'
import { SubCategoryRow } from '~/features/video/components/SubCategoryRow'

interface HomePageProps {
  keyVisuals: KeyVisualModel[]
  homeScreens: HomeScreen[]
  /** カテゴリー横の『全て表示』をクリック */
  onClickDisplayAll: (subCategoryId: number) => void
  /** ビデオカードをクリック */
  onClickVideoItem: (videoId: number) => void
  /** キービジュアルをクリック */
  onClickKeyVisual: (keyVisual: KeyVisualModel) => void
}

export function HomePageContent({
  keyVisuals,
  homeScreens,
  onClickDisplayAll,
  onClickVideoItem,
  onClickKeyVisual,
}: HomePageProps): JSX.Element {
  return (
    <Box>
      <KeyVisualsCarousel keyVisuals={keyVisuals} onClickThumbnail={onClickKeyVisual} />
      <Stack sx={{ paddingX: { xs: 2, sm: 4 }, paddingY: 2 }} spacing={{ xs: 3, sm: 4 }}>
        {homeScreens.map((homeScreen) => (
          <Box key={homeScreen.id}>
            <SubCategoryRow
              homeScreen={homeScreen}
              onClickDisplayAll={() => onClickDisplayAll(Number(homeScreen.category?.id))}
              onClickVideoItem={onClickVideoItem}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
