import { useQuery } from '@apollo/client'
import { useRecoilValue } from 'recoil'
import { GetHomeScreensDocument, GetHomeScreensQuery, HomeScreen } from '~/__generated__/graphql'
import { userLoginState } from '~/recoil/auth'

export function useHomeVideoList(): HomeScreen[] | 'loading' {
  const { data, error } = useQuery<GetHomeScreensQuery>(GetHomeScreensDocument)
  const loginState = useRecoilValue(userLoginState)

  if (error) {
    // TODO: 将来的にエラーをdispatchして、エラー画面が出るようにする
    console.error(error)
    throw error
  }

  if (loginState === 'loading' || data === undefined) {
    return 'loading'
  }

  return data.homeScreens
}
