import { Box, Card } from '@mui/material'
import { useMemo } from 'react'
import { Video } from '~/__generated__/graphql'
import { SlideGroup } from '~/components/SlideGroup'
import { VideoItemCard } from '~/features/video/components/Card/VideoItemCard'
import { VideoRankingItemCard } from '~/features/video/components/Card/VideoRankingItemCard'
import { getSizeStyle } from '../Card/VideoItemCard/getSizeStyle'

interface CardGroupProps {
  videos?: Video[]
  onClickVideoItem: (videoId: number, isKeyVisual: boolean) => void
}

export function DefaultCardGroup({ videos, onClickVideoItem }: CardGroupProps): JSX.Element {
  const sizeStyle = useMemo(() => getSizeStyle(false), [])

  return (
    <SlideGroup>
      {videos &&
        videos.map((video) => (
          <Box key={video.id} flexShrink={0}>
            <VideoItemCard
              video={video}
              onClickCard={() => onClickVideoItem(Number(video.id), false)}
            />
          </Box>
        ))}
      {/* Loading State */}
      {videos === undefined && <Card sx={sizeStyle} />}
    </SlideGroup>
  )
}

export function RankingCarGroup({ videos, onClickVideoItem }: CardGroupProps): JSX.Element {
  return (
    <SlideGroup>
      {videos &&
        videos.map((video, idx) => (
          <Box key={video.id} flexShrink={0}>
            {/* ランキングは1から始まるのでインクリメントする */}
            <VideoRankingItemCard
              video={video}
              onClickCard={() => onClickVideoItem(Number(video.id), false)}
              rankNumber={idx + 1}
            />
          </Box>
        ))}
      {/* Loading State */}
      {videos === undefined && (
        <Card
          sx={{
            width: { xs: 108, sm: 162 },
            height: { xs: 160, sm: 240 },
          }}
        />
      )}
    </SlideGroup>
  )
}
