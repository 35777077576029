import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { GetKeyVisualsDocument, GetKeyVisualsQuery } from '~/__generated__/graphql'
import { KeyVisualModel, createButtonInfo, createVideoInfo } from '../createModel'

export const useKeyVisual = () => {
  const { data, error, loading } = useQuery<GetKeyVisualsQuery>(GetKeyVisualsDocument)

  return useMemo(() => {
    if (!data?.screenPopups.edges) {
      return {
        error,
        loading,
        keyVisualModels: [],
      }
    }

    // キービジュアルの各要素を抽出 && 整形
    const baseKeyVisualModels: KeyVisualModel[] = data.screenPopups.edges
      .filter(({ node }) => node !== null)
      .map(({ node }) => {
        const { button, title, image, id } = node!
        const buttonInfo = button ? createButtonInfo(button) : undefined
        const videoInfo = button?.video ? createVideoInfo(button.video) : undefined

        return {
          id,
          title: button?.video?.title || title,
          image,
          buttonInfo,
          videoInfo,
        }
      })

    // buttonInfoとvideoInfoの両方が存在しない = 表示物がないとみなせるため除外
    const keyVisualModels = baseKeyVisualModels.filter(
      (item): item is KeyVisualModel => item !== null,
    )

    return {
      error,
      loading,
      keyVisualModels,
    }
  }, [data, error, loading])
}
